<template>
  <div class="form-wrapper">
    <div class="main-form">
      <card-basic>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: start;
          "
        >
          <h3>콘텐츠 기본정보</h3>
          <a v-if="liveLink" :href="liveLink" target="_blank">VOD 미리보기</a>
        </div>

        <div class="input-radio-area">
          <input-radio
            v-model.number="state.contentForm.type"
            name="type1"
            label="article"
            :value="1"
            for-value="contentType1"
          ></input-radio>
          <input-radio
            v-model.number="state.contentForm.type"
            name="type2"
            label="video"
            :value="3"
            for-value="contentType2"
          ></input-radio>
          <input-radio
            v-model.number="state.contentForm.type"
            name="type3"
            label="link"
            :value="5"
            for-value="contentType3"
          ></input-radio>
          <input-radio
            v-if="false"
            v-model.number="state.contentForm.type"
            name="type4"
            label="eduK"
            :value="300"
            for-value="contentType4"
          ></input-radio>
          <input-radio
            :model-value="state.contentForm.type"
            name="type5"
            label="video drm"
            :value="7"
            for-value="contentType5"
            @update:modelValue="
              (numberValue) => actions.updateContentDrmType(numberValue)
            "
          ></input-radio>
        </div>

        <embed-player
          v-if="
            state.contentForm.channelCode === 'youtube' &&
            state.contentForm.identifier
          "
          class="embed-player-area"
          :channel-code="state.contentForm.channelCode"
          :identifier="state.contentForm.identifier"
        ></embed-player>

        <input-submit
          v-model="state.contentForm.url"
          class="input-area"
          label="URL"
          placeholder="ex ) https://www.youtube.com/watch?v=~~~"
          btn-text="링크에서 정보 가져오기"
          :loading="state.getContentMetaLoading"
          @submitAction="actions.getContentMetaFromUrl()"
        ></input-submit>
        <template v-if="state.isEdit">
          <hr class="hr-third" />
          <h4>n-cloud 설정</h4>
          <input-basic-new
            class="input-area"
            label="drm-cid"
            :default-value="state.contentForm.drmCid"
            @updateData="(value) => actions.updateDrmCid(value)"
          ></input-basic-new>
          <div>
            <div class="url-dash-hls-header">
              <h5>동영상 파일 주소(웹용)</h5>
              <button-text
                text-size="s2"
                text="URL-dash 복사"
                padding="0 14px"
                text-weight="text"
                :is-icon="true"
                icon-position="back"
                color="#818287"
                @click="
                  () => {
                    actions.copyUrl(state.contentForm.urlDash);
                  }
                "
              >
                <template #icon>
                  <copy-icon fill-color="#0D0D10"></copy-icon>
                </template>
              </button-text>
              <button-text
                text-size="s2"
                text="URL-hls 복사"
                padding="0 14px"
                text-weight="text"
                :is-icon="true"
                icon-position="back"
                color="#818287"
                @click="
                  () => {
                    actions.copyUrl(state.contentForm.urlHls);
                  }
                "
              >
                <template #icon>
                  <copy-icon fill-color="#0D0D10"></copy-icon>
                </template>
              </button-text>
            </div>
            <input-basic-new
              class="input-area"
              label="URL-dash"
              :default-value="state.contentForm.urlDash"
              :error-message="
                isValidObj.urlDash ? '' : 'url 형식이 맞지 않아요.'
              "
              @updateData="(value) => actions.updateUrlDash(value)"
            ></input-basic-new>
            <input-basic-new
              class="input-area"
              label="URL-hls"
              :default-value="state.contentForm.urlHls"
              :error-message="
                isValidObj.urlHls ? '' : 'url 형식이 맞지 않아요.'
              "
              @updateData="(value) => actions.updateUrlHls(value)"
            ></input-basic-new>
          </div>
          <div>
            <div class="url-dash-hls-header">
              <h5>동영상 & 자막 파일 주소(앱용)</h5>
              <button-text
                text-size="s2"
                text="URL-dash-subtitle 복사"
                padding="0 14px"
                text-weight="text"
                :is-icon="true"
                icon-position="back"
                color="#818287"
                @click="
                  () => {
                    actions.copyUrl(state.contentForm.urlDashSubtitles);
                  }
                "
              >
                <template #icon>
                  <copy-icon fill-color="#0D0D10"></copy-icon>
                </template>
              </button-text>
              <button-text
                text-size="s2"
                text="URL-hls-subtitle 복사"
                padding="0 14px"
                text-weight="text"
                :is-icon="true"
                icon-position="back"
                color="#818287"
                @click="
                  () => {
                    actions.copyUrl(state.contentForm.urlHlsSubtitles);
                  }
                "
              >
                <template #icon>
                  <copy-icon fill-color="#0D0D10"></copy-icon>
                </template>
              </button-text>
            </div>
            <input-basic-new
              class="input-area"
              label="URL-dash-subtitle"
              :default-value="state.contentForm.urlDashSubtitles"
              :error-message="
                isValidObj.urlDashSubtitles ? '' : 'url 형식이 맞지 않아요.'
              "
              @updateData="(value) => actions.updateUrlDashSubtitles(value)"
            ></input-basic-new>
            <input-basic-new
              class="input-area"
              label="URL-hls-subtitle"
              :default-value="state.contentForm.urlHlsSubtitles"
              :error-message="
                isValidObj.urlHlsSubtitles ? '' : 'url 형식이 맞지 않아요.'
              "
              @updateData="(value) => actions.updateUrlHlsSubtitles(value)"
            ></input-basic-new>
          </div>
          <div>
            <div class="subtitle-lang-header">
              <div>
                <h5 class="subtitle-lang">자막언어</h5>
                <button-text
                  text-size="s2"
                  text="자막 추가하기"
                  padding="0 14px"
                  text-weight="text"
                  :is-icon="true"
                  icon-position="front"
                  color="#818287"
                  @click="actions.scrollIntoCaptionFormElement"
                >
                  <template #icon>
                    <plus-icon
                      width="12"
                      height="12"
                      fill-color="#0D0D10"
                    ></plus-icon>
                  </template>
                </button-text>
              </div>
              <div>
                <span class="b-text-2 text-gray-second">
                  {{ supportedSubtitles.text }}
                </span>
                <span class="b-text-2 text-warning">
                  {{ supportedSubtitles.message }}
                </span>
              </div>
            </div>
            <div v-if="supportedSubtitles.arr" class="subtitle-lang-btns">
              <button
                v-for="item in supportedSubtitles.arr"
                :key="item"
                class="bg-gray-studio"
                @click="actions.popSubTitles(item)"
              >
                <span class="sub-title-s2"> {{ item }} </span>
                <close-icon fill-color="#818287"></close-icon>
              </button>
            </div>
          </div>
          <hr class="hr-third" />
        </template>
        <input-basic
          class="input-area"
          :default-value="state.contentForm.title"
          label="제목"
          placeholder="콘텐츠 제목을 입력해 주세요."
          :required="true"
          @updateData="(value) => actions.updateTitle(value)"
        ></input-basic>

        <div class="description-area">
          <input-text-box-new
            label="콘텐츠 내용 요약"
            placeholder="콘텐츠 내용을 요약해보세요."
            :max-rows="10"
            :height-resize="false"
            :model-value="state.contentForm.description"
            @update:modelValue="
              (value) => {
                actions.updateDescription(value);
              }
            "
          ></input-text-box-new>
          <quill-editor
            label="본문 내용 요약"
            placeholder="본문 내용을 입력하세요."
            :value="state.contentForm.text"
            :editor-container-height="500"
            :is-align-toolbar="true"
            :limit-copypast-text-foramtting="false"
            @updateValue="(value) => actions.updateText(value)"
          >
          </quill-editor>
        </div>

        <div v-if="content" class="attachment-add">
          <h5>첨부파일 추가</h5>
          <button-basic
            class="attachment-btn"
            bg-color="#f5f4f8"
            :disabled="state.addAttachmentLoading"
            @action="actions.openAttachmentSelector()"
          >
            <template #icon>
              <plus-icon stroke-size="normal"></plus-icon>
            </template>
          </button-basic>

          <input
            ref="attachmentFileInput"
            type="file"
            accept=".jpg,.pdf,.doc,.png,.gif,.jpeg,.webp"
            hidden
            @change="actions.addAttachment($event)"
          />
        </div>
        <div>
          <ent-attachment-file-link
            v-for="attachment in state.attachments"
            :key="attachment.resourceId"
            :attachment="attachment"
            :direct-delete="false"
            @popAttachment="
              (resourceId) => {
                actions.deleteAttachment(resourceId);
              }
            "
          ></ent-attachment-file-link>
        </div>
      </card-basic>

      <card-basic v-if="content" class="multipart-wrapper">
        <div class="multipart-area">
          <div>
            <div>
              <h5>영상 파일 업로드 (영상만 인코딩, mp4)</h5>
            </div>
            <div>
              <ent-multipart-file-uploader
                :name="'ncloud-only-video'"
                :file-id="content.resourceId"
                :trigger-route-leave="state.triggerRouteLeave"
                :is-uploading="uploadingMultipart.isUploading"
              ></ent-multipart-file-uploader>
            </div>
          </div>
          <div>
            <div>
              <h5>영상 파일 업로드 (자동 자막 생성,mp4)</h5>
            </div>
            <div>
              <ent-multipart-file-uploader
                :name="'ncloud-video-subtitles'"
                :is-only-video-encoding="false"
                :file-id="content.resourceId"
                :trigger-route-leave="state.triggerRouteLeave"
                :is-uploading="uploadingMultipart.isUploading"
              ></ent-multipart-file-uploader>
            </div>
          </div>
        </div>

        <div v-if="uploadingMultipart.isUploading" class="progress-area">
          <div class="file-label">
            <p class="sub-text-s2 text-gray-second">
              {{ uploadingMultipart.fileName }}
            </p>
            <p class="sub-text-s2 text-gray-second">
              {{ uploadingMultipart.fileFormat }}
            </p>
          </div>
          <div class="progress">
            <div class="sub-text-s3 text-blue-50 progress-status">
              파일 업로드 {{ progress }} %
            </div>
            <div class="bg-gray-010 progress-bar">
              <div :style="{ width: `${progress}%` }" class="bg-blue-50"></div>
            </div>
          </div>
          <div class="actions">
            <button-basic
              class="close-btn"
              bg-color="transparent"
              text="취소"
              text-size="s3"
              color="#818287"
              padding="10px"
              :disabled="uploadingMultipart.isUploadSuccess"
              @action="actions.cancelMultipartUpload()"
            ></button-basic>
          </div>
        </div>
      </card-basic>

      <card-basic v-if="content" ref="cpationFormElment" class="caption-form">
        <div>
          <h5>vtt 자막파일 추가</h5>
        </div>
        <div>
          <div class="caption-th">
            <span class="sub-text-s2 text-gray-second lang">언어코드 </span>
            <span class="sub-text-s2 text-gray-second name">파일명</span>
            <span class="sub-text-s2 text-gray-second date">생성일</span>
          </div>
          <ul class="subtitle-file-list">
            <li v-for="(item, index) in vttCaptionFiles" :key="index">
              <ent-attachment-subtitle-file
                :index="index"
                :code="item.id"
                :extension="item.extension"
                :attachment="item.caption"
                :disable="state.addCaptionLoading"
                @add-subtitle-file="
                  (data) => actions.addSubtitleAttachment(item.id, data)
                "
                @pop-attachment="
                  (resourceId) => actions.deleteCaption(resourceId)
                "
              ></ent-attachment-subtitle-file>
            </li>
          </ul>
        </div>
      </card-basic>

      <card-basic v-if="content" class="caption-form">
        <div>
          <h5>srt 자막파일 추가</h5>
        </div>
        <div>
          <div class="caption-th" style="display: flex; gap: 16px">
            <span class="sub-text-s2 text-gray-second lang">언어코드 </span>
            <span class="sub-text-s2 text-gray-second name">파일명</span>
            <span class="sub-text-s2 text-gray-second date">생성일</span>
          </div>
          <ul class="subtitle-file-list">
            <li v-for="(item, index) in srtCaptionFiles" :key="index">
              <ent-attachment-subtitle-file
                :index="index"
                :code="item.id"
                :extension="item.extension"
                :attachment="item.caption"
                :disable="state.addCaptionLoading"
                @add-subtitle-file="
                  (data) => actions.addSubtitleAttachment(item.id, data)
                "
                @pop-attachment="
                  (resourceId) => actions.deleteCaption(resourceId)
                "
              ></ent-attachment-subtitle-file>
            </li>
          </ul>
        </div>
      </card-basic>
    </div>

    <div class="sub-form">
      <card-basic>
        <ul>
          <li>
            <input-image
              label="이미지 변경"
              delete-text="삭제"
              :featured-image="state.prevFeaturedImage"
              @updateData="
                (previewImage) => {
                  actions.updatePreviewImage(previewImage);
                }
              "
              @deleteImage="actions.deleteContentFeaturedImage()"
            ></input-image>
          </li>
          <li v-if="isNcloudThumbnails" class="thumbnail">
            <label class="sub-text-s2">썸네일 선택</label>
            <ul>
              <li v-for="(item, index) in thumbnails" :key="index">
                <div class="overlay"></div>
                <div class="btn-wrapper">
                  <button-basic
                    shape="round"
                    bg-color="#e6eaef"
                    padding="12px 12px"
                    @action="
                      () => {
                        actions.selectThumbnail(item);
                      }
                    "
                  >
                    <template #icon>
                      <edit-icon
                        width="16"
                        height="16"
                        fill-color="#0D0D10"
                      ></edit-icon>
                    </template>
                  </button-basic>
                </div>
                <img :src="item" alt="" />
              </li>
            </ul>
          </li>
          <li>
            <input-select-small
              v-model="state.contentForm.channelCode"
              label="채널"
              :list="channelList"
            ></input-select-small>
          </li>
          <li>
            <div>
              <div>
                <label class="sub-text-s2">공개일시</label>
                <span class="text-purple-50">*</span>
              </div>
              <input-date-picker
                :date="state.contentForm.publishedAt"
                :is-deletable="true"
                format="YYYY-MM-DD HH:mm"
                @updateDate="(date) => actions.updatePublishedAt(date)"
              ></input-date-picker>
            </div>
          </li>
          <li>
            <input-switch
              v-model="state.contentForm.isPaid"
              text-size="sub-text-s2"
              label="유료콘텐츠"
            ></input-switch>
          </li>
          <li>
            <input-switch
              v-model="state.contentForm.isUserOnly"
              text-size="sub-text-s2"
              label="회원 전용 콘텐츠"
            ></input-switch>
          </li>
          <li>
            <button-basic
              text="변경사항 저장"
              :disabled="!state.activeBtn || state.saveLoading"
              @action="actions.onBtnSaveContent()"
            ></button-basic>
          </li>
          <li>
            <button-basic
              :text="state.isEdit ? '삭제' : '취소'"
              :color="state.isEdit ? '#FF522D' : '#0d0d0d'"
              :bg-color="state.isEdit ? '#ffffff' : '#ECF1F4'"
              :border-color="state.isEdit ? '#FF522D' : ''"
              :border="state.isEdit"
              :disabled="state.deleteLoading"
              @action="
                state.isEdit
                  ? actions.showDeleteContentModal()
                  : actions.cancelContent()
              "
            ></button-basic>
          </li>
        </ul>
      </card-basic>
      <card-basic
        v-if="!state.isPrd && state.contentForm.type === 7"
        class="viewing-btns"
      >
        <h4>유저 시청환경 설정</h4>
        <ul>
          <li
            v-for="(item, index) in viewingMode"
            :key="`${index}-${item.value}`"
          >
            <input-switch
              v-model="state.contentForm[`${item.value}`]"
              text-size="sub-text-s2"
              :label="item.label"
            ></input-switch>
          </li>
        </ul>
      </card-basic>
    </div>

    <teleport :disabled="true">
      <warning-modal
        v-if="state.showPageOutWarning"
        warning-title="콘텐츠 작성을 취소할까요?"
        warning-text="이 페이지를 나가면 지금까지의 변경사항이 저장되지 않습니다."
        confirm-text="나가기"
        cancel-text="계속쓰기"
        @confirm="actions.pageOut()"
        @hideModal="actions.pageOutCancel()"
      ></warning-modal>

      <warning-modal
        v-if="state.showDeleteContentModal"
        warning-title="콘텐츠를 삭제하시겠습니까?"
        warning-text="삭제 후 복구는 불가능합니다."
        confirm-text="삭제"
        cancel-text="취소"
        @confirm="actions.deleteContent()"
        @hideModal="actions.closeDeleteContentModal()"
      ></warning-modal>

      <warning-modal
        v-if="state.showEssentialCheckModal"
        :is-cancel-btn="false"
        :warning-title="state.completeEssentialData.message"
        confirm-text="확인"
        @confirm="state.showEssentialCheckModal = false"
        @hideModal="actions.closeEssentialCheckModal()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, getCurrentInstance, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import moment from "moment-timezone";
import swal from "@/helper/swal";
import { onBeforeRouteLeave } from "vue-router";
import WarningModal from "@/components/console/modals/WarningModal.vue";
import InputSwitch from "@/components/console/inputs/InputSwitch.vue";
import InputSelectSmall from "../inputs/InputSelectSmall";
import EntAttachmentFileLink from "../files/EntAttachmentFileLink";
import EmbedPlayer from "../EmbedPlayer/EmbedPlayer";
import InputImage from "../inputs/InputImage";
import InputBasic from "@/components/console/inputs/InputBasic";
import InputSubmit from "../inputs/InputSubmit";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import InputDatePicker from "@/components/console/inputs/InputDatePicker.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import InputRadio from "@/components/console/inputs/InputRadio.vue";
import InputTextBoxNew from "../inputs/InputTextBoxNew";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";
import QuillEditor from "@/components/console/editors/QuillEditor.vue";
import EntAttachmentSubtitleFile from "@/components/console/files/EntAttachmentSubtitleFile.vue";
import EntMultipartFileUploader from "@/components/console/files/EntMultipartFileUploader.vue";
import EditIcon from "@/components/console/icons/EditIcon.vue";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";
import CopyIcon from "@/components/console/icons/CopyIcon.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import helper from "@/helper";
import { validateDashHlsUrl } from "@/helper/content";
import { cloneDeep } from "lodash";

export default {
  name: "EntContentsForm",
  components: {
    ButtonText,
    CloseIcon,
    EditIcon,
    CopyIcon,
    EntMultipartFileUploader,
    EntAttachmentSubtitleFile,
    QuillEditor,
    InputBasicNew,
    InputRadio,
    CardBasic,
    InputDatePicker,
    PlusIcon,
    ButtonBasic,
    InputSubmit,
    InputBasic,
    InputImage,
    EmbedPlayer,
    EntAttachmentFileLink,
    InputSelectSmall,
    InputSwitch,
    WarningModal,
    InputTextBoxNew,
  },
  props: {
    content: {
      type: Object,
      required: false,
    },
  },
  emits: ["cancelContent"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const typeList = [
      {
        id: "type1",
        value: 1,
        text: "article",
        iconClass: "far fa-newspaper",
      },
      {
        id: "type2",
        value: 3,
        text: "video",
        iconClass: "fab fa-youtube",
      },
      {
        id: "type3",
        value: 5,
        text: "link",
        iconClass: "fas fa-link",
      },
    ];
    const channelList = [
      {
        value: "",
        text: "채널을 선택해주세요.",
      },
      {
        value: "youtube",
        text: "youtube",
      },
      {
        value: "brunch",
        text: "brunch",
      },
      {
        value: "vimeo",
        text: "vimeo",
      },
      {
        value: "ncloud",
        text: "ncloud",
      },
    ];

    const cpationFormElment = ref(null);

    const attachmentFileInput = ref();
    const isEdit = !!props.content;

    const state = reactive({
      isEdit: computed(() => {
        return !!props.content;
      }),
      contentForm: {
        type: props.content ? props.content.type : 1,
        channelCode: props.content ? props.content.channelCode : "",
        identifier: props.content ? props.content.identifier : "",
        url: props.content ? props.content.url : "",
        urlDash: props.content ? props.content.urlDash : "",
        urlDashSubtitles: props.content ? props.content.urlDashSubtitles : "",
        urlHls: props.content ? props.content.urlHls : "",
        urlHlsSubtitles: props.content ? props.content.urlHlsSubtitles : "",
        drmCid: props.content ? props.content.drmCid : "",
        title: props.content ? props.content.title : "",
        description: props.content ? props.content.description : "",
        text: props.content ? props.content.text : "",
        featuredImage: props.content ? props.content.featuredImage : "",
        publishedAt: props.content?.publishedAt
          ? props.content.publishedAt
          : "",
        isPaid: props.content ? props.content.isPaid : false,
        isUserOnly: props.content ? props.content.isUserOnly : false,
        attachments: [], //attachment의 id 값이 모여 았는 곳
      },
      attachments: computed(() => {
        return props.content ? props.content.attachments : [];
      }),
      captions: computed(() => {
        return props.content ? props.content.captions : []; // attachment 자체
      }),
      completeEssentialData: computed(() => {
        let message = "";
        if (!state.contentForm.publishedAt) {
          message = "공개일시는 필수값 입니다.";
        }
        return {
          result: !!state.contentForm.publishedAt,
          message: message,
        };
      }),
      showEssentialCheckModal: false,
      getContentMetaLoading: false,
      addAttachmentLoading: false,
      addCaptionLoading: false,
      saveLoading: false,
      activeBtn: computed(() => {
        return (
          state.contentForm.title?.length > 0 && !!state.contentForm.publishedAt
        );
      }),
      deleteLoading: false,
      prevFeaturedImage: props.content
        ? props.content.featuredImage
        : proxy.$const.blankImage,
      pageOutApprove: false,
      showPageOutWarning: false,
      showDeleteContentModal: false,
      nextRouteName: null,
      triggerRouteLeave: false,
      resourceId: "",
      width: 0,
      subTitlesArr: [],
      errMessage: {
        urlHls: "",
        urlDash: "",
        urlHlsSubtitle: "",
        urlDashSubtitle: "",
      },
      isPrd: process.env.NODE_ENV === "production",
    });

    const viewingMode = [
      {
        value: "isIosMoweb",
        label: "[iOS] 모바일웹 시청 허용",
      },
      {
        value: "isIosIframe",
        label: "[iOS] 앱에서 웹뷰어(iFrame) 사용 여부",
      },
      {
        value: "isAosMoweb",
        label: "[AOS] 모바일웹 시청 허용",
      },
      {
        value: "isAosIframe",
        label: "[AOS] 앱에서 웹뷰어(iFrame) 사용 여부",
      },
    ];

    const vttCaptionFiles = computed(() => {
      const arr = [
        { id: "ko", extension: "vtt", caption: {} },
        { id: "en", extension: "vtt", caption: {} },
        { id: "ja", extension: "vtt", caption: {} },
        { id: "zh_cn", extension: "vtt", caption: {} },
        { id: "zh_tw", extension: "vtt", caption: {} },
        { id: "es", extension: "vtt", caption: {} },
        { id: "vi", extension: "vtt", caption: {} },
        { id: "id", extension: "vtt", caption: {} },
        { id: "th", extension: "vtt", caption: {} },
      ];

      state.captions.forEach((item) => {
        if (item.extension === "vtt") {
          switch (item.lang) {
            case "ko":
              setCaptionFile(item, arr, 0, "한국");
              break;
            case "en":
              setCaptionFile(item, arr, 1, "영어");
              break;
            case "ja":
              setCaptionFile(item, arr, 2, "일본어");
              break;
            case "zh_cn":
              setCaptionFile(item, arr, 3, "중국어 간체");
              break;
            case "zh_tw":
              setCaptionFile(item, arr, 4, "중국어 번체");
              break;
            case "es":
              setCaptionFile(item, arr, 5, "스페인어");
              break;
            case "vi":
              setCaptionFile(item, arr, 6, "베트남어");
              break;
            case "id":
              setCaptionFile(item, arr, 7, "인도네시아어");
              break;
            case "th":
              setCaptionFile(item, arr, 8, "태국어");
              break;
            default:
              arr[arr.length].id = item.lang;
              arr[arr.length].caption = item;
              break;
          }
        }
      });

      return arr;
    });

    const srtCaptionFiles = computed(() => {
      const arr = [
        { id: "ko", extension: "srt", caption: {} },
        { id: "en", extension: "srt", caption: {} },
        { id: "ja", extension: "srt", caption: {} },
        { id: "zh_cn", extension: "srt", caption: {} },
        { id: "zh_tw", extension: "srt", caption: {} },
        { id: "es", extension: "srt", caption: {} },
        { id: "vi", extension: "srt", caption: {} },
        { id: "id", extension: "srt", caption: {} },
        { id: "th", extension: "srt", caption: {} },
      ];

      state.captions.forEach((item) => {
        // filter 고려
        if (item.extension === "srt") {
          switch (item.lang) {
            case "ko":
              setCaptionFile(item, arr, 0, "한국");
              break;
            case "en":
              setCaptionFile(item, arr, 1, "영어");
              break;
            case "ja":
              setCaptionFile(item, arr, 2, "일본어");
              break;
            case "zh_cn":
              setCaptionFile(item, arr, 3, "중국어 간체");
              break;
            case "zh_tw":
              setCaptionFile(item, arr, 4, "중국어 번체");
              break;
            case "es":
              setCaptionFile(item, arr, 5, "스페인어");
              break;
            case "vi":
              setCaptionFile(item, arr, 6, "베트남어");
              break;
            case "id":
              setCaptionFile(item, arr, 7, "인도네시아어");
              break;
            case "th":
              setCaptionFile(item, arr, 8, "태국어");
              break;
            default:
              arr[arr.length].id = item.lang;
              arr[arr.length].caption = item;
              break;
          }
        }
      });

      return arr;
    });

    const countVttCaptionFiles = computed(() => {
      let captions = state.captions;

      let numberOfVttCaptions = 0;

      for (let i = 0; i < captions.length; i++) {
        if (captions[i].extension === "vtt") {
          ++numberOfVttCaptions;
        }
      }

      return numberOfVttCaptions;
    });

    const supportedSubtitles = computed(() => {
      let urlDashSubtitle = state.contentForm.urlDashSubtitles;
      let urlHlsSubtitle = state.contentForm.urlHlsSubtitles;

      const result = {
        message: "",
        text: "",
        arr: [],
      };

      // url-hls-Subtitle, url-dash-Subtitle 값이 있다.
      if (urlDashSubtitle && urlHlsSubtitle) {
        let dashArr = getSupportedLangFromUrlSubtitle(urlDashSubtitle);
        let hlsArr = getSupportedLangFromUrlSubtitle(urlHlsSubtitle);

        let dashArrLength = dashArr.length;
        let hlsArrLength = hlsArr.length;

        const case0 = dashArrLength > 0 && hlsArrLength > 0;
        const case4 = dashArrLength > 0 || hlsArrLength > 0;

        // url-hls-Subtitle에 url-dash-Subtitle 자막 정보가 있다.
        if (case0) {
          const case1 = dashArrLength === hlsArrLength;
          // url-hls-Subtitle, url-dash-Subtitle 두개의 자막 정보가 같다.
          if (case1) {
            result.text =
              "지원하는 언어의 언어코드 입니다. 클릭시 자막을 삭제하실 수 있습니다.";
            result.arr = dashArr;
          }

          // url-hls-Subtitle, url-dash-Subtitle 두개의 자막 정보가 같지 않다.
          if (!case1) {
            result.arr = dashArr;
            result.message = "dash와 hls의 자막 입력이 다릅니다.";
          }

          const case2 = countVttCaptionFiles.value === 0;
          // vtt 자막 파일이 없다.
          if (case2) {
            result.message =
              "자막 파일 주소와 등록된 자막 파일이 일치하지 않습니다.";
          } else {
            // vtt 자막 파일이 있다.
            const case3 =
              dashArrLength !== countVttCaptionFiles.value ||
              hlsArrLength !== countVttCaptionFiles.value;
            // 자막 파일이 있으나, 자막 파일과 url-hls-Subtitle, url-dash-Subtitle의 자막 정보가 다르다.
            if (case3) {
              result.message =
                "자막 파일 주소와 등록된 자막 파일이 일치하지 않습니다.";
            }
          }
          // url-hls-Subtitle, url-dash-Subtitle 두개의 자막 정보가 다르다.
        } else if (case4) {
          // 하나라도 없거나
          result.text = "등록된 자막이 없습니다.";
          result.message = "dash와 hls의 자막 입력이 다릅니다.";
        } else {
          // url-hls-Subtitle, url-dash-Subtitle 자막 정보가 없다.
          result.text = "등록된 자막이 없습니다.";
          const case5 = countVttCaptionFiles.value;
          // url-hls-Subtitle, url-dash-Subtitle 자막 정보는 없으나, vtt 자막 파일은 존재한다.
          if (case5) {
            result.message =
              "자막 파일 주소와 등록된 자막 파일이 일치하지 않습니다.";
          }
        }
        // url-hls-Subtitle, url-dash-Subtitle 값이 없다.
      } else {
        console.log("case5");
        result.arr = undefined;
        result.text = "등록된 자막이 없습니다.";
      }
      return result;
    });

    const uploadingMultipart = computed(() => {
      let multipartFiles = store.getters["contents/multipartFiles"];
      let isUploadingData = multipartFiles.length > 0;
      if (isUploadingData) {
        const index = multipartFiles.findIndex((item) => {
          return item.resourceId === state.resourceId;
        });
        return multipartFiles[index];
      } else {
        return { isUploading: false };
      }
    });

    const progress = computed(() => {
      if (uploadingMultipart.value.isUploading) {
        let denominator = Number(uploadingMultipart.value.numberOfFiles);
        let numerato = Number(uploadingMultipart.value.progress);
        let rates = ((numerato / denominator) * 100).toFixed(2);
        return rates;
      } else {
        return 0;
      }
    });

    const isNcloudThumbnails = computed(() => {
      return props.content?.urlHls && props.content?.channelCode === "ncloud";
    });

    const thumbnails = computed(() => {
      if (state.resourceId) {
        let arr = [];
        let env = process.env.NODE_ENV === "development" ? "dev" : "prd";
        for (let i = 1; i < 11; i++) {
          let imageUrl = `https://xpx2fylp2310.edge.naverncp.com/vodsThumbs/${env}_content_${
            state.resourceId
          }/${env}_content_${state.resourceId}_${i < 10 ? "0" + i : i}.jpg`;
          arr[i - 1] = imageUrl;
        }
        return arr;
      } else {
        return [];
      }
    });

    const liveLink = computed(() => {
      if (props.content) {
        const link = `${process.env.VUE_APP_LIVE_URL}/vod/${props.content.resourceId}/player`;
        return props.content.urlHls && props.content.urlDash ? link : "";
      } else {
        return "";
      }
    });

    const isValidObj = computed(() => {
      const isUrlDash =
        state.contentForm.urlDash !== null &&
        state.contentForm.urlDash.length > 0;
      const isUrlHls =
        state.contentForm.urlHls !== null &&
        state.contentForm.urlHls.length > 0;
      const isUrlDashSubtitle =
        state.contentForm.urlDashSubtitles !== null &&
        state.contentForm.urlDashSubtitles.length > 0;
      const isUrlHlsSubtitle =
        state.contentForm.urlHlsSubtitles !== null &&
        state.contentForm.urlHlsSubtitles.length > 0;

      // url 이 있고, validateDash가 true인 경우

      const status = {
        urlDash: true,
        urlHls: true,
        urlDashSubtitles: true,
        urlHlsSubtitles: true,
      };

      if (isUrlDash) {
        status.urlDash = validateDashHlsUrl("dash", state.contentForm.urlDash);
      }

      if (isUrlHls) {
        status.urlHls = validateDashHlsUrl("hls", state.contentForm.urlHls);
      }

      if (isUrlDashSubtitle) {
        status.urlDashSubtitles = validateDashHlsUrl(
          "dash",
          state.contentForm.urlDashSubtitles
        );
      }

      if (isUrlHlsSubtitle) {
        status.urlHlsSubtitles = validateDashHlsUrl(
          "hls",
          state.contentForm.urlHlsSubtitles
        );
      }

      // const status = {
      //   urlDash: isUrlDash && validateDashHlsUrl(state.contentForm.urlDash),
      //   urlHls: isUrlHls && validateDashHlsUrl(state.contentForm.urlHls),
      //   urlDashSubtitles:
      //     isUrlDashSubtitle &&
      //     validateDashHlsUrl(state.contentForm.urlDashSubtitles),
      //   urlHlsSubtitles:
      //     isUrlHlsSubtitle &&
      //     validateDashHlsUrl(state.contentForm.urlHlsSubtitles),
      // };

      return status;
    });

    onBeforeRouteLeave((to) => {
      state.nextRouteName = to.name;
      if (!state.pageOutApprove) {
        state.showPageOutWarning = true;
        return false;
      }
    });

    const getSupportedLangFromUrlSubtitle = (urlSubtitle) => {
      let splitStr = urlSubtitle.split(",");

      let filteredStr = splitStr.filter((item) => item.includes("lang"));

      let splitTranslatedStr = filteredStr.map((item) =>
        item.split("translated_")
      );

      let result = splitTranslatedStr.map((item, index) => {
        const indexIncludeVttStr = item.findIndex((element) =>
          element.includes(".vtt")
        );

        return item[indexIncludeVttStr].replace(".vtt", "");
      });

      return result;
    };

    onMounted(() => {
      if (!isEdit) {
        state.pageOutApprove = true;
      }
      state.resourceId = props.content?.resourceId;

      if (
        isNcloudThumbnails.value &&
        state.contentForm.featuredImage === proxy.$const.blankImage
      ) {
        let featuredImage = thumbnails.value[4];
        state.contentForm.featuredImage = featuredImage;
        state.prevFeaturedImage = featuredImage;
      }

      let urlSubtitle = state.contentForm.urlDashSubtitles;
      // cannot read properties or null
      if (urlSubtitle) {
        state.subTitlesArr = getSupportedLangFromUrlSubtitle(urlSubtitle);
      }

      // Drm type이면 회원 전용 콘테츠로 업데이트.
      // vision 프로 콘텐츠 설정때문에 해당 로직 제거.
      // 깃으로 변경된 코드 확인.
      // if (state.contentForm.type === 7) {
      //   state.contentForm.isUserOnly = true;
      // }
    });

    const isEntPagePath = () => {
      let backRouter = router.options.history.state.back;
      return backRouter.includes("/console/ent/contents");
    };

    const updateContentForm = (model = null) => {
      let updatedContent = store.getters["contents/content"];

      Object.keys(state.contentForm).forEach((key) => {
        if (!(model === "metaContent" && key === "isUserOnly")) {
          state.contentForm[key] = updatedContent[key];
        }
      });
      //todo 필요한 값인가? 확인 수정 파일의 경우는 있었음.
      // state.contentForm.featuredImage = updatedContent.featuredImage;
      state.prevFeaturedImage = updatedContent.featuredImage;
    };

    const setCaptionFile = (item, arr, index) => {
      // 자막파일이 기존에 존재하는 경우, 마지막 자막으로 배치
      if (arr[index].caption?.resourceId) {
        arr[arr.length] = {
          id: item.lang,
          extension: item.extension,
          caption: item,
        };
      } else {
        arr[index].caption = item;
      }
    };

    const actions = {
      getContentMetaFromUrl: () => {
        state.getContentMetaLoading = true;
        store
          .dispatch("contents/getContentMetaFromUrl", {
            url: state.contentForm.url,
          })
          .then(() => {
            updateContentForm("metaContent");
            state.getContentMetaLoading = false;
          });
      },
      updateTitle: (value) => {
        state.contentForm.title = value;
      },
      updateContentDrmType: (value) => {
        state.contentForm.type = Number(value);
        state.contentForm.isUserOnly = true;
      },
      updateDescription: (value) => {
        state.contentForm.description = value;
      },
      updateText: (value) => {
        state.contentForm.text = value;
      },
      updatePublishedAt: (value) => {
        state.contentForm.publishedAt = value ? moment(value) : "";
      },
      updatePreviewImage: (previewImage) => {
        state.prevFeaturedImage = previewImage;
        state.contentForm.featuredImage = previewImage;
      },
      updateUrlDash: (value) => {
        state.contentForm.urlDash = value;
      },
      updateUrlDashSubtitles: (value) => {
        state.contentForm.urlDashSubtitles = value;
      },
      updateUrlHls: (value) => {
        state.contentForm.urlHls = value;
      },
      updateUrlHlsSubtitles: (value) => {
        state.contentForm.urlHlsSubtitles = value;
      },
      updateDrmCid: (value) => {
        state.contentForm.drmCid = value;
      },
      openAttachmentSelector: () => {
        attachmentFileInput.value.click();
      },
      addAttachment: (e) => {
        state.addAttachmentLoading = true;
        const files = e.target.files || e.dataTransfer.files;

        const formData = new FormData();
        formData.append("file", files[0]);

        let payload = {
          resourceId: props.content.resourceId,
          file: formData,
        };

        e.target.value = "";

        store.dispatch("contents/uploadContentAttachment", payload).then(() => {
          swal.createCompleteToast();
          state.addAttachmentLoading = false;
        });
      },
      addSubtitleAttachment: (lang, data) => {
        const formData = new FormData();
        data.type === "file"
          ? formData.append("file", data.file)
          : formData.append("fileUrl", data.file);
        formData.append("lang", lang);

        let payload = {
          resourceId: props.content.resourceId,
          file: formData,
        };

        store.dispatch("contents/uploadContentCaption", payload).then(() => {
          swal.createCompleteToast();
          const { urlHlsSubtitles, urlDashSubtitles } = props.content;
          state.contentForm.urlHlsSubtitles = urlHlsSubtitles;
          state.contentForm.urlDashSubtitles = urlDashSubtitles;
        });
      },
      deleteAttachment: (attachmentResourceId) => {
        let payload = {
          resourceId: attachmentResourceId,
        };
        store.dispatch("contents/deleteContentAttachment", payload).then(() => {
          swal.deleteCompleteToast();
        });
      },
      deleteCaption: (captionResourceId) => {
        let payload = {
          resourceId: captionResourceId,
        };
        store.dispatch("contents/deleteContentCaption", payload).then(() => {
          swal.deleteCompleteToast();
          //   구조 변경 필요
          const { urlHlsSubtitles, urlDashSubtitles } = props.content;
          state.contentForm.urlHlsSubtitles = urlHlsSubtitles;
          state.contentForm.urlDashSubtitles = urlDashSubtitles;
        });
      },
      onBtnSaveContent: () => {
        state.saveLoading = true;

        if (!state.completeEssentialData.result) {
          state.showEssentialCheckModal = true;
          state.saveLoading = false;
          return;
        }

        if (state.attachments.length > 0) {
          state.contentForm.attachments = [];
          state.attachments.forEach((item) => {
            state.contentForm.attachments.push(item.resourceId);
          });
        }

        const formData = cloneDeep(state.contentForm);

        if (state.isPrd) {
          delete formData.isIosMoweb;
          delete formData.isIosIframe;
          delete formData.isAosMoweb;
          delete formData.isAosIframe;
        }

        if (isEdit) {
          store
            .dispatch("contents/putContent", {
              resourceId: props.content.resourceId,
              data: formData,
            })
            .then(() => {
              state.pageOutApprove = true;
              updateContentForm();
              swal.editCompleteToast();
              state.saveLoading = false;
            });
        } else {
          store.dispatch("contents/postContent", formData).then(() => {
            state.pageOutApprove = true;
            swal.createCompleteToast();
            state.saveLoading = false;
            const isEntPage = isEntPagePath();
            if (isEntPage) {
              router.push({
                path: `/console/ent/contents/${store.getters["contents/content"].resourceId}/edit`,
              });
            } else {
              router.push({
                name: "console.contents.edit",
                params: {
                  contentResourceId:
                    store.getters["contents/content"].resourceId,
                },
              });
            }
          });
        }
      },
      deleteContentFeaturedImage: () => {
        state.prevFeaturedImage = proxy.$const.blankImage;
        state.contentForm.featuredImage = "";
      },
      deleteContent: async () => {
        state.deleteLoading = true;

        let res = await store.dispatch(
          "contents/deleteContent",
          props.content.resourceId
        );

        if (res) {
          // 연결된 콘텐츠 삭제 시
          if (res.error) {
            swal.messageErrorAlert(res.error.message);
          }
          swal.messageErrorAlert(`티켓에 연결된 콘텐츠는 삭제가 불가능합니다.`);
          state.deleteLoading = false;
        } else {
          // 콘텐츠 삭제
          swal.deleteCompleteToast().then(() => {
            state.showDeleteContentModal = false;
            state.pageOutApprove = true;
            state.deleteLoading = false;
            emit("cancelContent");
          });
        }
      },
      pageOut: () => {
        state.triggerRouteLeave = true;
        state.pageOutApprove = true;
        state.showPageOutWarning = false;
        router.push({ name: state.nextRouteName });
      },
      pageOutCancel: () => {
        state.nextRouteName = null;
        state.showPageOutWarning = false;
      },
      closeEssentialCheckModal: () => {
        state.showEssentialCheckModal = false;
      },
      cancelContent: () => {
        emit("cancelContent");
      },
      showDeleteContentModal: () => {
        state.showDeleteContentModal = true;
      },
      closeDeleteContentModal: () => {
        state.showDeleteContentModal = false;
      },
      selectThumbnail: (imageUrl) => {
        state.prevFeaturedImage = imageUrl;
        state.contentForm.featuredImage = imageUrl;
      },
      cancelMultipartUpload: () => {
        let resourceId = uploadingMultipart.value.resourceId;
        store.dispatch("contents/cancelUploadingMultipart", resourceId);
      },
      popSubTitles: (value) => {
        const isCaption = state.captions.length > 0;
        if (isCaption) {
          let lang = value.toLowerCase().replace("-", "_");
          let caption = state.captions.find((item) => {
            return item.lang === lang && item.extension === "vtt";
          });
          actions.deleteCaption(caption.resourceId);
        } else {
          swal.errorToast("해당 자막 파일이 존재 하지 않습니다.");
        }
      },
      copyUrl: (value) => {
        const url = value;
        helper.copyToClipboard(url);
      },
      scrollIntoCaptionFormElement: () => {
        cpationFormElment.value.$el.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      },
    };

    return {
      state,
      actions,
      typeList,
      channelList,
      moment,
      attachmentFileInput,
      liveLink,
      vttCaptionFiles,
      srtCaptionFiles,
      supportedSubtitles,
      thumbnails,
      isNcloudThumbnails,
      uploadingMultipart,
      progress,
      cpationFormElment,
      isValidObj,
      countVttCaptionFiles,
      viewingMode,
    };
  },
};
</script>

<style scoped>
.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.form-wrapper .main-form h3 {
  margin-bottom: 24px;
}

.form-wrapper .multipart-wrapper {
  margin-top: 24px;
}

.form-wrapper .multipart-wrapper .multipart-area {
  display: flex;
  gap: 20px;
}

.form-wrapper .multipart-wrapper .multipart-area > div {
  width: 50%;
}

.form-wrapper .multipart-wrapper .progress-area {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.form-wrapper .multipart-wrapper .progress-area .file-label {
  max-width: 130px;
}

.form-wrapper .multipart-wrapper .progress-area .file-label p {
  margin-bottom: 4px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.form-wrapper .multipart-wrapper .progress-area .file-label p:last-child {
  margin-bottom: 0;
}

.form-wrapper .multipart-wrapper .progress-area .progress {
  flex-grow: 1;
}

.form-wrapper .multipart-wrapper .progress-area .progress-bar {
  flex-grow: 1;
  height: 4px;
  border-radius: 4px;
  overflow: hidden;
}

.form-wrapper .progress-area .progress .progress-status {
  margin-bottom: 4px;
  text-align: center;
}

.form-wrapper .progress-area .progress .actions button {
  padding: 4px;
}

.form-wrapper .multipart-wrapper .progress-area .progress-bar div {
  height: inherit;
  transition: width 0.2s;
}

.form-wrapper .caption-form {
  margin-top: 24px;
}

.form-wrapper .caption-form .caption-th {
  display: flex;
  gap: 16px;
}

.form-wrapper .caption-form .caption-th .lang {
  width: 60px;
}

.form-wrapper .caption-form .caption-th .name {
  flex-grow: 1;
}

.form-wrapper .caption-form .caption-th .date {
  width: 144px;
}

.attachment-add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ecebf1;
  padding-top: 16px;
  margin-top: 16px;
}

.attachment-add h5 {
  margin-bottom: 0;
}

.attachment-add .attachment-btn {
  width: 48px;
}

.sub-form ul li {
  margin-bottom: 20px;
}

.sub-form ul li:last-child {
  margin-bottom: 0;
}

.sub-form {
  height: fit-content;
}

.description-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-wrapper .sub-form .thumbnail label {
  display: block;
  margin-bottom: 8px;
}

.form-wrapper .sub-form .thumbnail ul {
  display: flex;
  width: 100%;
  border-radius: 8px;
  overflow-x: scroll;
}

.form-wrapper .sub-form .thumbnail ul li {
  border: 2px #f5f7fa solid;
  border-top: none;
  border-bottom: none;
  height: fit-content;
  width: 305px;
  margin-bottom: 0;
  position: relative;
}

.form-wrapper .sub-form .thumbnail ul li .btn-wrapper {
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-wrapper .sub-form .thumbnail ul li .btn-wrapper button {
  width: fit-content;
}

.form-wrapper .sub-form .thumbnail ul li:first-child {
  border-left: none;
}

.form-wrapper .sub-form .thumbnail ul li:last-child {
  border-right: none;
}

.thumbnail ul li .overlay {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  border-radius: 9px;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
}

.thumbnail ul li:hover .overlay {
  opacity: 1;
}
.form-wrapper .sub-form .thumbnail ul li:hover .btn-wrapper {
  opacity: 1;
}

.form-wrapper .sub-form .thumbnail ul li img {
  display: block;
  object-fit: cover;
  width: auto;
  height: 200px;
}

@media (min-width: 768px) {
  .form-wrapper {
    padding: 24px;
    gap: 24px;
  }
}

@media (min-width: 1024px) {
  .form-wrapper {
    flex-direction: row;
    padding: 0;
  }

  .form-wrapper .main-form {
    width: 70%;
  }

  .form-wrapper .sub-form {
    width: 30%;
  }
}

.input-radio-area,
.embed-player-area,
.description-area,
.input-area {
  margin-bottom: 20px;
}

.input-radio-area {
  display: flex;
  gap: 10px;
}

.subtitle-file-list {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.subtitle-lang-header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

h5.subtitle-lang {
  margin-top: 0;
  margin-bottom: 0;
}

.subtitle-lang-btns {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.subtitle-lang-btns button {
  padding: 8px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 500px;
  cursor: pointer;
}

.url-dash-hls-header {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 12px;
}

.url-dash-hls-header h5 {
  flex-grow: 1;
  margin-bottom: 0;
}

.text-warning {
  color: #ffa54c;
}

.form-wrapper .sub-form .viewing-btns {
  margin-top: 24px;
}
</style>
